<template>
  <app-layout>
    <template #title>new result</template>
    <template #content>
      <div class="web-camera-container mx-auto">
        <form @submit.prevent="validateBeforeOpenModal" class="flex flex-col justify-center mx-auto space-y-4">
          <date-time-picker
            v-model="formData.timestamp"
            label="Date and Time"
            v-validate="'required'"
            name="DateTime"
            @input="setTimeString()"
          />

          <div>
            <form-input label="LOT Number" name="Lot" id="lot" v-model="formData.lot" v-validate="'required'" />
            <span v-show="errors.has('Lot')" class="text-xs text-red-500">{{ errors.first("Lot") }}</span>
          </div>
          <div>
            <form-select
              label="Sterilizer"
              name="Sterilizer"
              v-model="formData.selectedSterilizer"
              :options="sterilizersAvailable"
              v-validate="'required'"
            />
            <span v-show="errors.has('Sterilizer')" class="text-xs text-red-500">{{ errors.first("Sterilizer") }}</span>
          </div>
          <div>
            <form-select
              label="Cycles"
              name="Cycles"
              v-model="formData.selectedCycle"
              :options="cyclesAvailable"
              v-validate="'required'"
            />
            <span v-show="errors.has('Cycles')" class="text-xs text-red-500">{{ errors.first("Cycles") }}</span>
          </div>

          <p class="text-sm text-justify">
            If the processed "EX-SPORE Indicator" exposed to the steam sterilization cycle is an off-white color, 20
            seconds after adding indicator solution to the tablet, select PASS. If the indicator solution has turned a
            shade of red , select FAIL
          </p>

          <div class="my-4 mx-auto w-48">
            <radio-button-group v-model="formData.result" v-validate="'required'" name="Result" />
            <span v-show="errors.has('Result')" class="text-xs text-red-500">{{ errors.first("Result") }}</span>
          </div>

          <camera-input :profileIsComplete="profileIsComplete" @imageUpdate="handleImageUpdate($event)" />

          <transition name="fade">
            <p
              v-if="!userName || !fullName"
              class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500 text-center"
            >
              To submit your result please add your username and full name to the
              <router-link to="/profile">Account Profile</router-link>
            </p>
          </transition>

          <base-button
            type="submit"
            class="mb-4 mx-14"
            :disable="!userName || !fullName || !profileIsComplete"
            :primary="!!(userName && fullName && profileIsComplete)"
          >
            Submit Result
          </base-button>
        </form>
        <modal ref="checkPasswordBeforeSubmit">
          <div class="flex flex-column m-4">
            <p>Check your password:</p>
            <form
              class="flex flex-col justify-center space-y-10 mx-auto mt-8 w-72"
              @submit.prevent="validateBeforeSubmit"
            >
              <form-input
                class="w-full"
                name="password"
                type="password"
                placeholder="Password"
                v-validate="'required|min:6'"
                v-model="checkPassword"
              />
              <span v-show="errors.has('password')" class="text-xs text-red-500">{{ errors.first("password") }}</span>

              <div class="flex flex-row justify-between space-x-4 mt-6">
                <base-button @click="$refs.checkPasswordBeforeSubmit.closeModal()" primary>Cancel</base-button>
                <base-button type="submit" :disabled="false" primary>Submit</base-button>
              </div>
            </form>
          </div>
        </modal>
      </div>
    </template>
  </app-layout>
</template>

<script>
import { BaseButton, CameraInput, DateTimePicker, FormInput, FormSelect, Modal, RadioButtonGroup } from "@/components";
import { fireStorage } from "@/firebase/config";
import AppLayout from "@/layouts/AppLayout";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "SubmitResultPage",
  components: { AppLayout, BaseButton, CameraInput, DateTimePicker, FormInput, FormSelect, Modal, RadioButtonGroup },
  data() {
    return {
      formData: {
        lot: "",
        imageName: "",
        timestamp: 0,
        timeString: "",
        selectedSterilizer: "",
        selectedCycle: "",
        result: undefined
      },
      image: null,
      checkPassword: "",
      userName: "",
      fullName: ""
    };
  },

  methods: {
    handleImageUpdate(event) {
      this.image = event;
      this.buildImageName();
    },
    buildImageName() {
      const dateString = DateTime.now().toFormat("yyyy_LL_dd_HH_mm_ss");
      this.formData.imageName = `${this.user.uid}_${dateString}`;
    },
    uploadImage() {
      fireStorage
        .ref()
        .child(`Photos/${this.formData.imageName}`)
        .putString(this.image.split(",")[1], "base64", { contentType: "image/jpeg" })
        .catch((error) => console.error(error.message));
    },
    submitResult() {
      if (!this.errors.items.length) {
        if (this.image) this.uploadImage();

        const payload = {
          userName: this.userName,
          fullName: this.fullName,
          clinicData: this.clinic,
          sterilizer: {
            name: this.currentSterilizer.title,
            serialNumber: this.currentSterilizer.number
          },
          ...this.formData
        };

        if (!this.currentUser.noPrivateNotifications) {
          payload.selectedEmail = this.currentUser.email;
        }

        this.$store
          .dispatch("auth/userSetFormData", payload)
          .then(() => this.$router.push("/"))
          .then(() => this.$toast.success("EX-SPORE biological indicator test result was submitted successfully"))
          .then(() => {
            this.$store.dispatch("audit/addRecord", {
              actionName: "SUBMIT_RESULT",
              clinicUid: this.currentUser.clinicUid,
              notes: `Result submitted: ${JSON.stringify(this.formData)}`,
              uid: this.currentUser.uid,
              userEmail: this.currentUser.email,
              userName: this.currentUser.userName
            });
          });
      }
    },
    initDateTime() {
      this.formData.timestamp = DateTime.now().toMillis();
      this.setTimeString();
    },
    setTimeString() {
      this.formData.timeString = new DateTime.fromMillis(this.formData.timestamp).toFormat("dd/LL/yyyy HH:mm");
    },
    validateBeforeOpenModal() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$refs.checkPasswordBeforeSubmit.openModal();
        } else {
          console.log("The form will not be submitted. Correct the errors");
        }
      });
    },
    validateBeforeSubmit() {
      this.$store
        .dispatch("auth/verifyPassword", this.checkPassword)
        .then(() => this.submitResult())
        .catch(() => this.$toast.error("The password is incorrect. Please try again."));
    },
    async populateDefaultValues() {
      const result = await this.$store.dispatch("results/fetchLastResultData", this.currentUser.uid);

      if (result) {
        this.formData.selectedSterilizer = await result.selectedSterilizer;
        this.formData.selectedCycle = await result.selectedCycle;
      } else {
        this.formData.selectedSterilizer = this.sterilizersAvailable[0];
        this.formData.selectedCycle = this.cyclesAvailable[0];
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      currentUser: "auth/currentUser",
      clinic: "clinic/data",
      sterilizers: "clinic/sterilizers"
    }),

    profileIsComplete() {
      return this.sterilizers.length > 0 && this.currentUser.clinicUid != "";
    },

    sterilizersAvailable() {
      return this.sterilizers.map((item) => item.title);
    },

    currentSterilizer() {
      return this.sterilizers.filter((item) => item.title == this.formData.selectedSterilizer)[0];
    },

    cyclesAvailable() {
      if (this.currentSterilizer) {
        return this.currentSterilizer.cycles;
      }
      return [];
    }
  },
  created() {
    this.$store.dispatch("clinic/fetchClinicData", this.clinic.id);
    this.$store
      .dispatch("auth/fetchCurrentUserData", this.currentUser.uid)
      .then(() => {
        this.userName = this.currentUser.userName;
        this.fullName = this.currentUser.fullName;
      })
      .then(() => this.populateDefaultValues());
  },
  mounted() {
    this.initDateTime();
  }
};
</script>